import { createApp, h } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import Toast, { PluginOptions } from "vue-toastification";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import "@authentic-labs/vue-component-library/style.css";
import "@authentic-labs/survey-builder/style.css";
import "vue-toastification/dist/index.css";
import "./assets/scss/main.scss";

// @ts-ignore
import vueCountryRegionSelect from 'vue3-country-region-select'
// import vueCountryRegionSelect from "vue-country-region-select";

import { plugin, defaultConfig } from '@formkit/vue'
import "@formkit/themes/genesis";
import axios from "axios";
import moment from "moment";
import mitt from "mitt";
const emitter = mitt();

axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const app = createApp({                                                                                                                                                                                                                                                                                                     
  render: () => h(App),
});

app.use(store);
app.use(router);
app.use(plugin, defaultConfig);
app.use(vueCountryRegionSelect);

const options: PluginOptions = {
  timeout: 5000,
};
app.use(Toast, options);

app.provide('emitter', emitter);   
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$filters = {
  formatDate(value: Date) {
    if (value) {
      return moment(String(value)).format("MM/DD/YYYY");
    }
    return "";
  },
  formatEndDate(value: Date) {
    if (value) {
      if (new Date(value) < new Date()) {
        return "EXPIRED";
      } else {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    }
    return "";
  },
  capitalizeFirst(value: string) {
    if (value) {
      return value.charAt(0).toUpperCase() + value.substring(1);
    }
  },
  formatMonth(value: number) {
    if (value) {
      return value.toString().padStart(2, "0");
    }
  },
  formatName(value: string) {
    if (value) {
      const tmpNameArr = value.split(".");
      if (tmpNameArr[tmpNameArr.length - 1] === "css") {
        tmpNameArr.splice(tmpNameArr.length - 1, 1);
        return tmpNameArr.join();
      }
      return value;
    }
  },
  formatCurrency(value: number) {
    if (value) {
      return `$${value.toFixed(2)}`;
    }
  }
};
app.mount("#app");
