<template>
  <div class="form" ref="billing-details-form">
    <div class="row" style="display: block">

      <div id="overlay" :style="showOverlay ? 'display:flex' : 'display:none'" class="analytics-db-container">
        <div id="text" class="d-flex flex-column align-items-center justify-content-center">
          <div class="row">
            <div class="spinner-border text-primary" role="status">
            </div>
          </div>
          <div class="row">
            <span>Getting payment information...</span>
          </div>
        </div>
      </div>

      <div class="col px-0 px-sm-4 pt-3 pt-lg-0">
        <slot name="billing-title">
          <p class="card-text mb-3 pay-card">Pay with card</p>
        </slot>

        <div v-if="!hasPaymentMethod" class="form-group labels mb-0 ">
          <label for="name" class="form-label label-padding mb-0">
            <span class="text-danger">*</span>Name on card
          </label>
          <input id="name" type="text" placeholder="Enter name on card..." class="form-control mb-2"
            v-model="userName.value.value" :class="{'is-invalid': userName.errorMessage.value}" />
          <div v-if="userName.errorMessage.value" class="invalid-feedback">
            {{ userName.errorMessage.value }}
          </div>
        </div>

        <!-- <div v-if="!hasPaymentMethod" class="form-group labels mb-4">
          <label for="card" class="form-label label-padding mb-0">
            <span class="text-danger">*</span>Card number
          </label>
          <slot name="stripe">
            <stripe-element-card id="card" ref="elementRef" :pk="publishableKey" :elementStyle="cardStyle"
              @token="tokenCreated" />
          </slot>
        </div> -->

        <!-- Stripe Card Element -->
        <div v-if="!hasPaymentMethod" class="form-group labels mb-4">
          <label for="card" class="form-label label-padding mb-0">
            <span class="text-danger">*</span>Card number
          </label>
          <div id="card-element" class="StripeElement"></div>
        </div>

        <div class="form-group labels pb-2 mb-3" v-else>
          <label for="card-info" class="form-label mb-0">
            <div class="row">
              <div class="col-6">
                <p class="card-text">Card</p>
              </div>
            </div>
          </label>

          <div id="card-info" class="card card-info mb-2" v-if="paymentDetails && paymentDetails.card">
            <div class="row card-info-text my-auto">
              <div class="col-auto">
                <span class="pe-1">
                  {{ $filters.capitalizeFirst(paymentDetails.card.brand) }}
                </span>
                <span class="pe-1">
                  <span>&bull;&bull;&bull;&bull;</span>
                  {{ paymentDetails.card.last4 }}
                </span>
              </div>
              <div class="col">
                <span class="float-end">
                  Expires {{ $filters.formatMonth(paymentDetails.card.exp_month) }}/
                  {{ paymentDetails.card.exp_year }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group labels email mb-3 " id="card-email">
          <label for="email" class="form-label mb-0">Email</label>
          <input type="email" class="form-control mb-0" id="email" placeholder="Enter your email..." v-model="email"
            disabled />
        </div>

        <div class="form-group labels pt-0 mb-3" id="card-code">
          <label for="code" class="form-label mb-0">Coupon</label>
          <div class="row">
            <div class="col">
              <input type="text" class="form-control mb-0 d-inline-flex" id="code" placeholder="Enter discount code..."
                @focus="enterCoupon" v-model="code" />
            </div>
            <div class="col ps-0">
              <button ref="couponButton" class="btn coupon-button" :class="validateButton"
                :disabled="disableCouponSubmit" @click="validateCoupon">
                <div v-if="validating" class="apply">
                  <div class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></div>
                  <span class="sr-only">Validating...</span>
                </div>

                <div v-if="validationFailed" class="confirmed-text">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                    class="bi bi-x-circle bottom-space" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                  <span class="confirmed-text-span ps-1">Invalid code</span>
                </div>

                <div v-if="validationConfirmed" class="confirmed-text">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                    class="bi bi-check-circle bottom-space" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path
                      d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                  </svg>
                  <span class="confirmed-text-span">Code applied</span>
                </div>

                {{ couponButtonTitle }}
              </button>
            </div>
          </div>
          <div class="row" v-if="validationConfirmed">
            <div class="col">
              <label class="text-muted confirm-label mb-0 text-start">Your invoice will reflect the discounted
                price</label>
            </div>
          </div>
        </div>

        <div class="container-fluid p-0 m-0 mb-1" v-if="cardAction === 'save'">
          <div class="row">
            <div class="col">
              <label class="text-muted confirm-label text-start" style="margin-top:-0.75rem">
                By saving this payment method, you allow Authentic Labs to charge your card for future payments in
                accordance
                with their terms. This card will become your default payment method. Default payment methods can be
                changed under billing summary.
              </label>
            </div>
          </div>
          <div class="row pt-4" :class="newCardClass">
            <div class="col text-end">
              <div class="btn-group">
                <button type="button" class="btn btn-outline-secondary btn-sm rounded-pill ms-2" @click="cancelClicked">
                  Cancel
                </button>
                <button type="button" class="btn btn-sm rounded-pill ms-2" :class="submitButton" @click.prevent="submit"
                  :disabled="disableSubmit">
                  Save payment method
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid p-0 m-0" v-else>
          <button ref="subscribeButton" @click="submit" class="w-100" :class="['btn', 'btn-block', submitButton]"
            :disabled="disableSubmit">
              <div v-if="busy" class="spinner-border spinner-border-sm me-2" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            {{ buttonTitle }}
          </button>
          <label class="text-muted confirm-label">
            By confirming your subscription, you allow Authentic Labs to charge your card for this payment and future
            payments in accordance with their terms.
          </label>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import {
    StripeCheckout,
    StripeElementCard,
    StripeElementsPluginObject,
  } from "@vue-stripe/vue-stripe";

  import { loadStripe, Stripe, StripeCardElement, StripeElements } from "@stripe/stripe-js";
  import { Company, Product } from "@authentic-labs/vue-component-library";
  import User from "../../models/User";
  import { updateCompanyAdmin } from "../../services/AdminService";
  import {
    getCustomerStatus,
    intent,
    savePayment,
    subscribe,
    updateAdditionalProducts,
    checkCoupon,
  } from "@/services/PaymentService";
  import PaymentMethod from "../../models/PaymentMethod";
  import {
    getDefaultPayment,
    removeAdditionalProducts,
  } from "../../services/PaymentService";
  import { uploadFile } from "../../services/FileService";
  import { createProduct, fetchProducts } from "../../services/ProductService";
  import { computed, ref, inject, onMounted, markRaw } from "vue";
  import { useStore } from "vuex";
  import { useField, useForm } from "vee-validate";
  import { object, string } from "yup";
  import { useToast } from "vue-toastification";

  const $store = useStore();
  const emitter: any = inject("emitter");
  const toast = useToast();

  

  const props = defineProps({
    cardAction: String,
    onCancel: Function,
    subscriptionType: String,
    paymentFrequency: String,
    subscriptionChange: Boolean,
    newCard: Boolean,
  });

  // const elementRef = ref<StripeElementsPluginObject>();

  const stripe = ref<Stripe | null>(null);
  const elements = ref<StripeElements | null>(null);
  const elementRef = ref<StripeCardElement | null>(null); 


  const subscribeButton = ref<HTMLButtonElement>();
  const couponSpinner = ref<HTMLDivElement>();
  const validationResult = ref<HTMLElement>();

  const company = computed(() => {
    return $store.getters.company;
  });
  const user = computed(() => {
    return $store.getters.authUser;
  });
  const product = computed(() => {
    return $store.getters.product;
  });

  const newCardClass = computed(() => {
    return props.newCard ? "new-card" : "";
  });

  const subscription = computed(() => {
    return $store.getters.subscription;
  });

  const placeHolderHeaderImage = computed(() => {
    return new URL("/src/assets/images/app/placeholder16x9.png", import.meta.url)
      .href;
  });
  const placeHolderLogoImage = computed(() => {
    return new URL("/src/assets/images/app/placeholder1x1.png", import.meta.url)
      .href;
  });

  const validationSchema = markRaw(
    object({
      user: object({
        name: string().required("Name is required field"),
      }),
    })
  );
  const { validate, errors, meta } = useForm({ validationSchema });
  const userName = useField("user.name", validationSchema);

  const cardStyle = {
    base: {
      iconColor: "red",
      lineHeight: "25px",
      marginBottom: "0px",
      color: "#32315E",
      fontWeight: 400,
      fontFamily: "'proximanova-regular', sans-serif",
      fontSize: "14.4px",

      "::placeholder": {
        color: "#495057",
      },
    },
  };

  let buttonTitle = ref("Subscribe");
  let couponButtonTitle = ref("Apply code");
  let busy = ref(false);
  let validating = ref(false);
  let validationFailed = ref(false);
  let validationConfirmed = ref(false);
  let intentToken = "";
  let stripeToken: object = {};
  let email = ref("");
  let code = ref("");
  let hasPaymentMethod = ref(false);
  let paymentDetails = ref<Partial<PaymentMethod> | null>({});
  let showOverlay = ref(true);
  // let priceId = ref<string[]>([]);
  let priceId:any[] = [];
  let disableSubmit = ref(false);
  let disableCouponSubmit = ref(false);
  let submitButton = ref("btn-primary");
  let validateButton = ref("btn-outline-secondary");
  // const publishableKey = ref(import.meta.env.VITE_APP_STRIPE_TOKEN);
  const stripePromise = loadStripe(import.meta.env.VITE_APP_STRIPE_TOKEN);
  let hasName = ref(true);
  let payLoad: Partial<Product> = {};

  const confirmedButtonHTML = `<div class="confirmed-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                    </svg> <span class="confirmed-text-span">Subscription confirmed</span>
                  </div>`;

  onMounted(async () => {
    showOverlay.value = true;
    paymentDetails.value = null;
    if (props.cardAction !== "save") {
      paymentDetails.value = await getDefaultPayment();
    }
    if (!props.newCard) {
      setPriceId();
    }

    email = user.value.email;
    userName.value.value = user.value.name;
    // if (paymentDetails.value === null) {
    //   hasPaymentMethod.value = false;
    //   loadIntent();
    // } else {
    //   hasPaymentMethod.value = true;
    //   showOverlay.value = false;
    // }

    if (paymentDetails.value === null) {
      hasPaymentMethod.value = false;
      // Initialize Stripe and Elements
      stripe.value = await stripePromise;
      elements.value = stripe.value?.elements() as StripeElements;

      // Mount CardElement
      const cardElementOptions = {
        style: {
          base: {
            iconColor: "#32315E",
            lineHeight: "25px",
            color: "#32315E",
            fontWeight: 400,
            fontFamily: "'proximanova-regular', sans-serif",
            fontSize: "14.4px",
            "::placeholder": {
              color: "#495057",
            },
          },
        },
      };
      elementRef.value = elements.value?.create("card", cardElementOptions) as StripeCardElement;
      elementRef.value?.mount("#card-element");
      loadIntent();
    } else {
      hasPaymentMethod.value = true;
      showOverlay.value = false;
    }
  });

  async function loadIntent() {
    intentToken = await intent();
    showOverlay.value = false;
  }

  function setPriceId() {
    if (
      props.subscriptionType === "basic" &&
      props.paymentFrequency === "monthly"
    ) {
      const priceId_monthly = import.meta.env.VITE_APP_BASIC_MONTHLY;
      priceId = import.meta.env.VITE_APP_BASIC_MONTHLY;

    }
    if (
      props.subscriptionType === "pro" &&
      props.paymentFrequency === "monthly"
    ) {
      priceId = [import.meta.env.VITE_APP_PRO_MONTHLY];
    }
    // if (
    //   props.subscriptionType === "business" &&
    //   props.paymentFrequency === "monthly"
    // ) {
    //   priceId.value = [import.meta.env.VITE_APP_BUSINESS_MONTHLY];
    // }
    if (
      props.subscriptionType === "enterprise" &&
      props.paymentFrequency === "monthly"
    ) {
      priceId = [import.meta.env.VITE_APP_ENTERPRISE_MONTHLY];
    }
  }

  async function validateCoupon() {
    validating.value = true;
    disableCouponSubmit.value = true;
    couponButtonTitle.value = "Validating code...";
    const checkResponse = await checkCoupon(code.value);
    validating.value = false;
    couponButtonTitle.value = "";
    if (checkResponse === "valid") {
      validationConfirmed.value = true;
      validateButton.value = "btn-success";
    } else {
      validationFailed.value = true;
      validateButton.value = "btn-danger";
    }
  }

  function enterCoupon() {
    validateButton.value = "btn-outline-secondary";
    couponButtonTitle.value = "Apply code";
    disableCouponSubmit.value = false;
    validating.value = false;
    validationFailed.value = false;
    validationConfirmed.value = false;
  }

  async function submit() {
    const valid = await validate();
    if(!valid) {
      return;
    };
    let subscribeResponse = "";
    disableSubmit.value = true;
    const currentTotalProducts =
      subscription.value.additionalProducts + subscription.value.productNumber;
    if (paymentDetails.value) {
      const paymentDetailsUpdate = {
        paymentMethod: paymentDetails.value?.id,
        priceId: priceId,
        subscriptionType: props.subscriptionType,
        subscriptionChange: props.subscriptionChange,
        discountCode: code.value,
      };
      busy.value = true;
      buttonTitle.value = "Updating subscription...";
      subscribeResponse = await subscribe(paymentDetailsUpdate);
      await $store.dispatch("getSubscriptionStatus", { user: user });
      if (props.subscriptionChange) {
        await removeAdditionalProducts(paymentDetails.value);
        if (subscription.value.productNumber > currentTotalProducts) {
          const newProductCount =
            subscription.value.productNumber - currentTotalProducts;
          await createProducts(newProductCount);
        }
        paymentDetailsUpdate.priceId =
          import.meta.env.VITE_APP_ADDITIONAL_PRODUCT;
        // The following updates additional products if number of products is larger than what is included in the subscription.
        if (currentTotalProducts - subscription.value.productNumber > 0) {
          //@ts-ignore
          paymentDetailsUpdate["quantity"] =
            currentTotalProducts - subscription.value.productNumber;
          await updateAdditionalProducts(paymentDetailsUpdate);
        }
      }
      (subscribeButton as unknown as HTMLButtonElement).innerHTML = confirmedButtonHTML;
      busy.value = false;
      submitButton.value = "btn-success";
      company.value.status = "Active";
      updateCompany();
    } else {
      const cardSetupResult = await stripe.value?.confirmCardSetup(intentToken, {
        payment_method: {
          card: elementRef.value as any,
          billing_details: {
            // name: userName.value.value as string,
            // email: email.value,
            name: email.value
          },
        },
      });

      if (cardSetupResult?.error || !hasName) {
        disableSubmit.value = false;
        return;
      }
      props.cardAction === "save"
        ? savePaymentCompany(cardSetupResult)
        : subscribeCompany(cardSetupResult);
    }
  }

  async function createProducts(quantity: number) {
    let message = " new products were successfully added.";
    if (quantity === 1) {
      message = " new product was successfully added.";
    }
    payLoad = {
      model: "",
      name: "Product name",
      status: "active",
    };
    const placeholderResponse = await setPlaceholderImages();
    payLoad.imageFile = { filesId: placeholderResponse.fileHeaderId };
    payLoad.appLogoFile = { filesId: placeholderResponse.fileLogoId };
    try {
      for (let i = 0; i < quantity; i++) {
        const products = await createProduct(company.value.companyId, payLoad);
        emitter.emit("newProductAdded", products); 
      }
      toast.success(quantity + message);
    } catch (error) {
      toast.error("Something went wrong. Product was not added.");
      console.error("Error adding new products: " + error);
      return "error";
    }
    return "success";
  }

  async function setPlaceholderImages() {
    //set placeholder for header image
    const headerResponse = await fetch(placeHolderHeaderImage.value);
    const logoResponse = await fetch(placeHolderLogoImage.value);
    const headerBlob = await headerResponse.blob();
    const logoBlob = await logoResponse.blob();
    const headerFile = new File([headerBlob], "placeholder16x9.png", {
      type: headerBlob.type,
    });
    const logoFile = new File([logoBlob], "placeholder1x1.png", {
      type: logoBlob.type,
    });
    try {
      const fileHeader = await uploadFile(headerFile);
      const fileLogo = await uploadFile(logoFile);
      return { fileHeaderId: fileHeader.filesId, fileLogoId: fileLogo.filesId };
    } catch (error) {
      console.error('Error uploading placeholder images: ', error);
      return { fileHeaderId: 0, fileLogoId: 0};
    }
    
  }

  async function subscribeCompany(cardSetupResult: any) {
    busy.value = true;
    buttonTitle.value = "Confirming subscription...";
    const paymentDetails = {
      paymentMethod: cardSetupResult.setupIntent.payment_method,
      priceId: priceId,
      subscriptionType: props.subscriptionType,
      discountCode: code.value,
    };
    const subscribeResponse = await subscribe(paymentDetails);
    await $store.dispatch("getSubscriptionStatus", { user: user });
    const existingProducts = await fetchProducts(company.value.companyId!);
    const quantity = subscription.value.productNumber - existingProducts.length;
    if (quantity > 0 && subscription.value.productNumber !== 1000) {
      await createProducts(quantity);
    } 
    ( subscribeButton as unknown as HTMLButtonElement ).innerHTML = confirmedButtonHTML;
    busy.value = false;
    submitButton.value = "btn-success";
    company.value.status = "Active";
    updateCompany();
    paymentResponse(subscribeResponse);
  }

  async function savePaymentCompany(cardSetupResult: any) {
    const savePaymentResponse = await savePayment({
      paymentMethod: cardSetupResult.setupIntent.payment_method,
    });
    if (props.newCard) {
      emitter.emit("billing-summary", "refresh");
    } else {
      updateCompany();
    }
    paymentResponse(savePaymentResponse);
  }

  function paymentResponse(status: string) {
    if (props.newCard) {
      toast.success("A payment method was successfully added");
    } else if (status === "success" && props.cardAction === "save") {
      toast.success("A payment method was successfully created");
      emitter.emit("subscription-modal");
    } else if (status === "success" && !props.cardAction) {
      toast.success("Successfully subscribed to " +
          titleCase(props.subscriptionType as string) +
          " content");
    } else {
      toast.error("There was an issue with creating a payment");
    }
  }

  function cancelClicked() {
    if (props.onCancel) {
      props.onCancel();
    }
  }

  function tokenCreated(token: any) {
    stripeToken = token;
  }

  function managePayments() {
    console.log(
      "...add the logic to move to payment managment and close current dialog"
    );
  }

  function titleCase(string: string) {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  }

  async function updateCompany() {
    const companyUpdate = company;
    company.value.type = props.subscriptionType;
    try {
      await updateCompanyAdmin(company.value.companyId, company.value);
      toast.success("Company details for " +
          companyUpdate.value.companyName +
          " have been successfully update!")
      $store.dispatch("getCompany", company.value.companyId);
      setTimeout(() => emitter.emit("close-payment-modal"), 500);
      emitter.emit("billing-summary", "refresh");
    } catch {
      toast.error("Company update failed, please try again");
    }
  }
</script>

<style scoped>
:deep .StripeElement {
  box-sizing: border-box;
  height: calc(1.6em + 0.75rem + 2px) !important;
  padding: 8px 12px !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.5rem !important;
  background-color: white;
  box-shadow: none !important;
}

:deep .StripeElement--focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #a8c5f5 !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgb(54 121 232 / 25%) !important;
}

:deep .StripeElement--is-error {
  color: red !important;
  border-color: red !important;
}

:deep #stripe-element-errors {
  color: red;
  font-size: 14px;
}

#overlay {
  position: absolute;
  display: none;
  width: 98%;
  height: 98%;
  top: 0px;
  left: 8px;
  right: 0;
  bottom: 0;
  background-color: rgba(250, 250, 250, 0.85);
  z-index: 3;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}

.coupon-button {
  border-radius: 0.5rem;
  width: 100%;
}

.apply {
  display: inline-block;
}

.spinner-border {
  /* vertical-align: sub !important; */
  margin-right: 5px;
  /* margin-bottom: 2px; */
}

.btn.btn-success {
  transition-duration: 0.75s;
  animation-name: colorTransition;
}

@keyframes colorTransition {
  from {
    background-color: #3679e8 !important;
  }

  to {
    background-color: #38c172 !important;
  }
}

:deep .confirmed-text-span {
  margin-left: 0.25rem;
  display: inline-block;
  vertical-align: middle;
}

:deep .confirmed-text {
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
  animation: fadeIn linear 0.75s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.new-card {
  /* margin-top: 125px; */
  margin-top: 115px;
}

.card-info {
  height: 37px;
  border-radius: 0.5rem;
  font-size: 14.4px;
  white-space: nowrap;
}

.card-info-text {
  flex-wrap: nowrap;
  padding-left: 1rem;
  padding-right: 1rem;
}

.default {
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.labels-names {
  font-size: 16px !important;
}

.labels {
  font-size: 16px;
}

.label-padding {
  padding-top: 5px;
  padding-left: 2px;
  padding-right: 5px;
}

.email {
  margin-top: -1.25rem;
}

.confirm-label {
  font-size: 12px;
  display: block;
  text-align: center;
  margin-top: 2px;
}

#card-input.form-group {
  margin-bottom: 0.5rem;
}

:deep .col-form-label {
  padding-bottom: 0px;
}

.payment-waiting {
  white-space: nowrap;
  font-family: "proximanova-medium", sans-serif;
}

.pay-card {
  font-family: "proximanova-medium", sans-serif;
  font-size: 16px;
}

.spacer {
  margin-top: 2.25rem !important;
}

.invalid-feedback {
  font-size: 14px;
  margin-top: -8px;
}

/* .spinner-border {
  margin-right: 2px;
  vertical-align: sub;
  margin-bottom: 0px;
} */

.bottom-space {
  margin-bottom: 1px;
}
</style>
