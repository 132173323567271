<template>
  <authentic-modal ref="billingModal" id="billing-modal" :on-submit="submit" :on-cancel="cancel" :size="'lg'"
    :title="modalTitle" :hideFooter="true" :no-close-on-backdrop="true">
    <div id="overlay" :style="showModalOverlay ? 'display:flex' : 'display:none'" class="analytics-db-container">
      <div id="text" class="d-flex flex-column align-items-center justify-content-center">
        <div class="row">
          <div class="spinner-border text-primary" role="status">
          </div>
        </div>
        <div class="row">
          <span>{{overlayMessage}}</span>
        </div>
      </div>
    </div>
    <div id="testsize" class="container billing">
      <transition name="component-fade" mode="out-in">
        <div>
          <BillingSummary v-if="billingSummary" :billing-details="(billingInformation as Billing)" />
          <BillingUpdatePlan v-if="billingUpdate" :billing-details="(billingInformation as Billing)" />
          <BillingAddPayment v-if="addPayment" />
          <BillingAddItems v-if="updateQuantity" :billing-details="(billingInformation as Billing)" :unarchive="unarchive" />
          <BillingCancelPlan v-if="billingCancelPlan" :billing-details="(billingInformation as Billing)" />
        </div>
      </transition>
    </div>
  </authentic-modal>
</template>

<script setup lang="ts">
  import AuthenticModal from "../shared/AuthenticModal.vue";
  import {
    getCustomerStatus,
    setDefaultPayment,
    removePayment,
  } from "../../services/PaymentService";
  import User from "../../models/User";
  import Billing from "../../models/Billing";
  import BillingUpdatePlan from "./BillingUpdatePlan.vue";
  import BillingSummary from "./BillingSummary.vue";
  import BillingAddPayment from "./BillingAddPayment.vue";
  import BillingAddItems from "./BillingAddItems.vue";
  import BillingCancelPlan from "./BillingCancelPlan.vue";
  import { computed, ref, inject, onBeforeMount, onBeforeUnmount } from "vue";
  import { useStore } from "vuex";

  const billingModal = ref<typeof AuthenticModal>();
  const $store = useStore();
  const emitter: any = inject("emitter");

  const user = computed((): User => {
    return $store.getters.authUser;
  })

  const showModalOverlay = ref(false);
  const overlayMessage = ref("Getting account information...");

  const billingUpdate = ref(false);
  const billingSummary = ref(true);
  const addPayment = ref(false);
  const updateQuantity = ref(false);
  const billingCancelPlan = ref(false);
  const modalTitle = ref("Billing");
  const billingInformation = ref<Partial<Billing> | null >({});
  const unarchive = ref(false);

  onBeforeMount(() => {
    $store.dispatch("getPlans");
    emitter.on("billing-modal-close", () => {
      billingModal.value!.hideModal();
    });

    emitter.on("update-plan", () => {
      billingSummary.value = false;
      billingUpdate.value = true;
      addPayment.value = false;
      updateQuantity.value = false;
      billingCancelPlan.value = false;
    });

    emitter.on("billing-summary", async (update: string) => {
      if (update === "refresh") {
        overlayMessage.value = "Getting updated account information...";
        showModalOverlay.value = true;
        billingInformation.value = await getCustomerStatus(user.value);
        showModalOverlay.value = false;
      }
      billingSummary.value = true;
      billingUpdate.value = false;
      addPayment.value = false;
      updateQuantity.value = false;
      billingCancelPlan.value = false;
    });

    emitter.on("billing-add-payment", () => {
      billingSummary.value = false;
      billingUpdate.value = false;
      addPayment.value = true;
      updateQuantity.value = false;
      billingCancelPlan.value = false;
    });

    emitter.on(
      "billing-update-quantity",
      async (_updateQuantity: {productList: boolean, fromArchive: boolean}) => {
        billingSummary.value = false;
        billingUpdate.value = false;
        addPayment.value = false;
        billingCancelPlan.value = false;
        if (_updateQuantity.productList) {
          showModalOverlay.value = true;
          billingModal.value!.showModal();
          billingInformation.value = await getCustomerStatus(user.value);
          showModalOverlay.value = false;
        }
        unarchive.value = _updateQuantity.fromArchive;
        updateQuantity.value = true;
      }
    );

    emitter.on("billing-modal", async () => {
      billingSummary.value = true;
      billingUpdate.value = false;
      addPayment.value = false;
      updateQuantity.value = false;
      billingCancelPlan.value = false;

      showModalOverlay.value = true;
      if (billingModal.value) {
        billingModal.value.showModal();
      }
      billingInformation.value = await getCustomerStatus(user.value);
      showModalOverlay.value = false;
    });

    emitter.on("cancel-plan", async () => {
      billingSummary.value = false;
      billingUpdate.value = false;
      addPayment.value = false;
      updateQuantity.value = false;
      billingCancelPlan.value = true;
      billingInformation.value = await getCustomerStatus(user.value);
    });
  });

  function submit() {
    billingModal.value!.hideModal();
  }

  function cancel() {
    billingModal.value!.hideModal();
  }

  onBeforeUnmount(() => {
    emitter.off("billing-management")
    emitter.off("billing-modal");
    emitter.off("billing-modal-close");
  });
</script>

<style scoped>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}

.component-fade-enter-from,
.component-fade-leave-to {
  opacity: 0;
}

#overlay {
  position: absolute;
  display: none;
  width: 98%;
  height: 98%;
  top: 0px;
  left: 8px;
  right: 0;
  bottom: 0;
  background-color: rgba(250, 250, 250, 0.85);
  z-index: 3;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}

:deep .tooltip-inner {
  text-align: left;
}

.payment-methods {
  min-height: 105px;
}

.billing {
  height: 575px !important;
  padding: 0;
  margin: 0;
}

.history-container {
  overflow: auto;
  max-height: 136px;
}

.default-action {
  opacity: 0.5;
}

:deep .set-card-spacing {
  padding-right: 3rem !important;
}

:deep .set-expire-spacing {
  padding-right: 1.5rem !important;
}

:deep .b-table-sticky-header {
  max-height: 125px;
}

:deep .dropdown .btn {
  background-color: transparent !important;
  color: #495057 !important;
  border: none;
}

:deep .table th,
.table td {
  vertical-align: middle !important;
  border-top: none;
  background-color: #fff !important;
}

:deep .dropdown .btn-secondary:focus,
.btn-secondary.focus {
  color: #495057;
  border-color: #545b62;
  box-shadow: none;
}

:deep .btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
  color: #495057;
  background-color: transparent !important;
  border-color: none;
}

h5 {
  font-family: "proximanova-medium", sans-serif !important;
}

.current {
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
}

.subscription-plan {
  font-family: "proximanova-bold", sans-serif;
  font-size: 20px;
}

.subscription-price {
  font-family: "proximanova-regular", sans-serif;
  font-size: 20px;
}

.subscription-renewal {
  margin-top: -0.5rem;
  font-family: "proximanova-regular", sans-serif;
  font-size: 16px;
  white-space: nowrap;
}

.default {
  background-color: #e9ecef;
  border-radius: 0.25rem;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
}

.edit-dropdown {
  background-color: transparent !important;
}
</style>
