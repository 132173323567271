/**
 * Service for company API calls
 */
import { apiClient } from "./Api";
import { Company } from "@authentic-labs/vue-component-library";
import User, { UserDTO } from "../models/User";
import Plan from "../models/Plan";

export async function getPlans() {
  const { data: response } = await apiClient.get(`/api/plans`);
  const plans: Plan[] = [];
  response.forEach(
    (plan: {
      name: string;
      interval: string;
      numberProducts: number;
      numberProductSections: number;
      ctaNumber: number;
      premiumCompanyContent: boolean;
      price: number;
    }) => {
      plans.push({
        name: plan.name,
        interval: plan.interval,
        productNumber: plan.numberProducts,
        productSections: plan.numberProductSections,
        ctaNumber: plan.ctaNumber,
        premium: !!plan.premiumCompanyContent,
        price: plan.price,
      });
    }
  );
  return plans;
}

export async function checkCoupon(coupon: string) {
  const { data: response } = await apiClient.get(
    `/api/codeCheck?coupon=` + coupon
  );
  return response.status;
}

export async function intent() {
  const { data: response } = await apiClient.get(`/api/intent`);
  if (response.status === "success") {
    return response.intent.client_secret;
  }
}

export async function savePayment(payLoad: object) {
  const { data: savePaymentResponse } = await apiClient.post(
    `/api/savePayment`,
    payLoad
  );
  return savePaymentResponse.status;
}

export async function removePayment(payLoad: object) {
  const { data: removePaymentResponse } = await apiClient.post(
    `/api/removePayment/`,
    payLoad
  );
  const sortedPM = removePaymentResponse.paymentMethods.sort(
    (a: { default: boolean }, b: { default: boolean }) =>
      a.default > b.default ? -1 : 1
  );
  return {
    status: removePaymentResponse.status,
    paymentMethods: sortedPM,
  };
}

export async function subscribe(payLoad: object) {
  const { data: subscribeRepsonse } = await apiClient.post(
    `/api/subscribe`,
    payLoad
  );
  return subscribeRepsonse.status;
}

export async function cancelPlan() {
  const { data: response } = await apiClient.get(`/api/cancelPlan`);
  if (response.status === "success") {
    return response.status;
  }
}

export async function updateAdditionalProducts(payLoad: object) {
  const { data: subscribeRepsonse } = await apiClient.post(
    `/api/updateAdditionalProducts`,
    payLoad
  );
  return subscribeRepsonse.status;
}

export async function removeAdditionalProducts(payLoad: object) {
  const { data: subscribeRepsonse } = await apiClient.post(
    `/api/removeAdditionalProducts`,
    payLoad
  );
  return subscribeRepsonse.status;
}

export async function getPaymentMethods() {
  const { data: response } = await apiClient.get(`/api/paymentMethods`);
  if (response.status === "success") {
    return response;
  }
}

export async function getStatus() {
  const { data: response } = await apiClient.get(`/api/status`);
  if (response.status === "success") {
    return response.status;
  }
}

export async function getDefaultPayment() {
  const { data: response } = await apiClient.get(`/api/defaultPayment`);
  return response.paymentMethod;
}

export async function setDefaultPayment(payLoad: object) {
  const { data: response } = await apiClient.post(
    `/api/setDefaultPayment`,
    payLoad
  );
  if (response.status === "success") {
    const sortedPM = response.paymentMethods.sort(
      (a: { default: boolean }, b: { default: boolean }) =>
        a.default! > b.default ? -1 : 1
    );
    return {
      status: response.status,
      paymentMethods: response.paymentMethods,
    };
  } else {
    return null;
  }
}

export async function getCustomerStatus(user: User) {
  const { data: response } = await apiClient.get(`/api/customer`);
  if (user.role === "User" && response.status === "success") {
    return {
      role: "companySubscribedUser",
      subscriptionName: response.subscriptionName,
    };
  }
  if (response.status === "success") {
    const sortedPM = response.customerBilling.paymentMethods.sort(
      (a: { default: boolean }, b: { default: boolean }) =>
        a.default > b.default ? -1 : 1
    );
    return {
      subscriptionName: response.customerBilling.subscriptionName.split(" ")[0],
      paymentInterval: response.customerBilling.paymentFreq,
      planPrice: response.customerBilling.price,
      paymentMethods: sortedPM,
      additionalProducts: response.additionalProducts,
      additionalCtas: response.additionalCtas,
      additionalUsers: response.additionalUsers,
      productSections: response.productSections,
      productNumber: response.productNumber,
      ctaNumber: response.ctaNumber,
      userNumber: response.userNumber,
      billingHistory: response.billingHistory,
      renewalDate: response.customerBilling.renewalDate,
      cancellationDate: response.customerBilling.cancellationDate,
      additionalProductPrice: response.additionalProductPrice,
      additionalCtaPrice: response.additionalCtaPrice,
      additionalUserPrice: response.additionalUserPrice,
    };
  } else {
    return null;
  }
}

// export async function updateUser(companyId:number, id: number, user: User) {
//   const userDto = UserDTO.create(user)
//   const { statusText: response } = await apiClient.put<string>(
//     `/api/companies/${companyId}/users/${id}`,
//     userDto
//   );
//   return response;
// }
