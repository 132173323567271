<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="card text-center px-4" :class="monthlyClass">
          <div class="card-text frequency mt-2" :class="visible">
            Billed Monthly
          </div>
          <div class="row">
            <div class="col">
              <span class="dollar">$</span>
              <span class="subscription-price">{{ Math.trunc(pricing as number) }}</span>
            </div>
          </div>
          <div class="card-text mb-2" :class="visible">USD / Month</div>
          <a href="#" class="stretched-link" @click="submit('monthly')"></a>
      </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Plan from '@/models/Plan';
import { defineProps, computed, inject, onMounted } from 'vue';

const emitter: any = inject('emitter');

const props = defineProps<{
  subscriptionType?: Partial<Plan>;
  upgrade?: boolean;
}>();

const monthlyClass = computed(() => "subscription-" + props.subscriptionType?.name);
const yearlyClass = computed(() => "subscription-" + props.subscriptionType?.name + "-year");
const pricing = computed(() => props.subscriptionType?.price);
const visible = computed(() => "");

onMounted(() => {
  console.log(props.subscriptionType, "subscriptionType");
});

function submit(freq: string) {
  emitter.emit("subscription-modal-close");
  emitter.emit("upgrade-premium-close");

  emitter.emit("payment-modal", {
    planType: props.subscriptionType,
    upgrade: props.upgrade,
  });
}
</script>

<style scoped>
.frequency {
  margin-bottom: 0.1rem;
}

.hide-text {
  visibility: hidden;
}

.subscription-basic {
  border-radius: 0.75rem;
  border-color: #14a899;
  transition: 0.25s;
  border-width: 2px;
  height: 125px;
}
.subscription-basic:hover {
  border-radius: 0.75rem;
  border-color: #d5f9de;
  white-space: nowrap;
  color: #074f57;
  background-color: #d5f9de;
  border-width: 2px;
}

.subscription-pro {
  border-radius: 0.75rem;
  border-color: #074f57;
  transition: 0.25s;
  border-width: 2px;
}
.subscription-pro:hover {
  border-radius: 0.75rem;
  border-color: #14a899;
  color: white;
  background-color: #14a899;
  border-width: 2px;
}

.subscription-enterprise {
  border-radius: 0.75rem;
  border-color: #074f57;
  transition: 0.25s;
  border-width: 2px;
}
.subscription-enterprise:hover {
  border-radius: 0.75rem;
  border-color: #074f57;
  color: white;
  background-color: #074f57;
  border-width: 2px;
}

.subscription-price {
  font-size: 36px;
  font-family: "proximanova-medium", sans-serif;
  padding-left: 0.8rem;
}
.dollar {
  font-size: 1.25rem;
  vertical-align: top;
  position: absolute;
  top: 2.2rem;
}
</style>
