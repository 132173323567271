<template>
    <authentic-modal ref="addItemModal" id="add-item-modal" :on-submit="submit" :on-cancel="cancel" :size="'md'"
      :title="modalTitle" :hideFooter="true" :no-close-on-backdrop="true">
      <div class="container px-4" id="message" v-html="message">
      </div>
  
      <div class="row justify-content-end action-spacing">
        <div class="col-auto">
          <div class="btn-group pe-4 fix-buttons">
            <button class="btn btn-outline-secondary btn-sm rounded-pill ms-2" @click.prevent="cancel">Cancel</button>
            <button class="btn btn-primary btn-sm rounded-pill ms-2" @click.prevent="submit">Continue to billing</button>
          </div>
        </div>
      </div>
    </authentic-modal>
  </template>
  
  <script setup lang="ts">
    import { ref, computed, onMounted, onBeforeUnmount, inject } from "vue";
    import { useStore } from "vuex";
    import { useToast } from "vue-toastification";
    import { defineProps } from "vue";
    import AuthenticModal from "../AuthenticModal.vue";
  
    const addItemModal = ref<typeof AuthenticModal>();
    const store = useStore();
    const toast = useToast();
    const emitter: any = inject("emitter");
    const usuck = ref("")
  
    const props = defineProps<{
      modalTitle?: string;
      message: string;
    }>();
  
   
    
    onMounted(() => {
      emitter.on("add-new-item", async () => {
        addItemModal.value?.showModal();
      });
    });
  
    
  
    async function submit() {
      try {
        emitter.emit('user-management-close')
        emitter.emit("billing-update-quantity", {productList: true, fromArchive: false});
        addItemModal.value?.hideModal();
      } catch (error) {
        console.error(error);
        toast.error("There was a problem adding this CTA!");
      }
    }
  
    function cancel() {
      addItemModal.value?.hideModal();
    }
  
    onBeforeUnmount(() => {
      emitter.off("add-new-item");
    });
  </script>
  
  <style scoped>

  .modal-title {
    padding-left: .7rem !important;
  }
  
  </style>
  