<template>
  <div class="container-fluid d-flex h-100 flex-column">
    <div class="row g-0">
      <div class="col">
        <h5 class="current mb-3">Current plan: {{ billingDetails.subscriptionName === "Basic"
              ? "Basic"
              : billingDetails.subscriptionName === "Pro"
              ? "Professional"
              : billingDetails.subscriptionName === "Enterprise"
              ? "Enterprise"
              : ""}} at {{ $filters.formatCurrency(billingDetails.planPrice) }}
          / {{ billingDetails.paymentInterval }}</h5>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col">
        <div class="row g-0">
          <div class="row g-0">
            <p class="card-text subscription-renewal"><b>Number of included Experiences available on your current plan:
                {{ billingDetails.productNumber }}</b></p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="card-text">You can add additional Experiences to your current plan for {{
            $filters.formatCurrency(billingDetails.additionalProductPrice) }} / month
          </p>
        </div>
      </div>

      <div class="row my-3 align-items-center current">
        <div class="col-auto">
          <label for="demo-sb">Purchase additional Experiences (shows current number of added Experiences): </label>
        </div>
        <div class="col d-flex justify-content-end">
          <div class="spinner form-control d-flex align-items-stretch" role="group" tabindex="-1">
            <button class="btn btn-sm border-0 rounded-0 py-0" tabindex="-1" type="button"
              @click="updateProductNumbers('dec')">
              <i class="bi bi-dash" style="font-size: 1.5em;"></i>
            </button>
            <output class="flex-grow-1 align-self-center border-left border-right" dir="ltr" id="demo-sb"
              role="spinbutton" tabindex="0"><bdi>{{ productNumber }}</bdi>
            </output>
            <button class="btn btn-sm border-0 rounded-0 py-0" tabindex="-1" type="button"
              @click="updateProductNumbers('inc')">
              <i class="bi bi-plus" style="font-size: 1.5em;"></i>
            </button>
          </div>
        </div>
      </div>

      <div v-if="billingDetails.subscriptionName !== 'Basic'" class="col pt-2">
        <div class="row g-0">
          <div class="row g-0">
            <p class="card-text subscription-renewal"><b>Number of included CTAs available on your current plan:
                {{ billingDetails.ctaNumber }}</b></p>
          </div>
        </div>
      </div>
      <div v-if="billingDetails.subscriptionName !== 'Basic'" class="row">
        <div class="col">
          <p class="card-text">You can add additional CTAs to your current plan for {{
            $filters.formatCurrency(billingDetails.additionalCtaPrice) }} / month
          </p>
        </div>
      </div>
      <div v-if="billingDetails.subscriptionName !== 'Basic'" class="row my-3 align-items-center current">
        <div class="col-auto">
          <label for="demo-sb">Purchase additional CTAs (shows current number of added CTAs):</label>
        </div>
        <div class="col d-flex justify-content-end">
          <div class="spinner form-control d-flex align-items-stretch" role="group" tabindex="-1">
            <button class="btn btn-sm border-0 rounded-0 py-0 spinner-button" tabindex="-1" type="button"
              @click="updateCtaNumbers('dec')">
              <i class="bi bi-dash" style="font-size: 1.5em;"></i>
            </button>
            <output class="flex-grow-1 align-self-center border-left border-right" dir="ltr" id="demo-sb"
              role="spinbutton" tabindex="0"><bdi>{{ ctaNumber }}</bdi>
            </output>
            <button class="btn btn-sm border-0 rounded-0 py-0 spinner-button" tabindex="-1" type="button"
              @click="updateCtaNumbers('inc')">
              <i class="bi bi-plus" style="font-size: 1.5em;"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="col pt-2">
        <div class="row g-0">
          <div class="row g-0">
            <p class="card-text subscription-renewal"><b>Number of included Users available on your current plan:
                {{ billingDetails.userNumber }}</b> </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="card-text">You can add additional products to your current plan for {{
            $filters.formatCurrency(billingDetails.additionalUserPrice) }} / month
          </p>
        </div>
      </div>
      <div class="row my-3 align-items-center current">
        <div class="col-auto">
          <label for="demo-sb">Purchase additional User seats (shows current number of added Users):</label>
        </div>
        <div class="col d-flex justify-content-end">
          <div class="spinner form-control d-flex align-items-stretch" role="group" tabindex="-1">
            <button class="btn btn-sm border-0 rounded-0 py-0 spinner-button" tabindex="-1" type="button"
              @click="updateUserNumbers('dec')">
              <i class="bi bi-dash" style="font-size: 1.5em;"></i>
            </button>
            <output class="flex-grow-1 align-self-center border-left border-right" dir="ltr" id="demo-sb"
              role="spinbutton" tabindex="0"><bdi>{{ userNumber }}</bdi>
            </output>
            <button class="btn btn-sm border-0 rounded-0 py-0 spinner-button" tabindex="-1" type="button"
              @click="updateUserNumbers('inc')">
              <i class="bi bi-plus" style="font-size: 1.5em;"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="row pt-3">
        <div class="pe-0 col-7">
          <p class="card-text current-payment" v-if="showUpdatedPricing">What you will pay {{ freq }} starting
            {{ billingDetails.renewalDate }}</p>
          <p class="card-text current-payment" v-else>What you will pay {{ freq }}</p>
        </div>
        <div class="col">
          <div class="row">
            <!-- <div class="text-end">${{ updatedPrice }}</div> -->
            <div class="text-end">{{ $filters.formatCurrency(updatedPrice) }}</div>
          </div>

          <div class="row">
            <div class="text-end text-muted default-payment">
              <span class="pe-1">{{
                $filters.capitalizeFirst(defaultPayment.brand)
                }}</span>
              <span class="pe-0"><span>&bull;&bull;&bull;&bull;</span>
                {{ defaultPayment.last_four }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row flex-grow-1 align-items-end text-end row-bottom pb-3 pe-0">
        <div class="col mt-4 align-right">
          <div class="btn-group" role="group">
            <button class="btn btn-outline-secondary btn-sm rounded-pill ms-2 action-button" type="button"
              @click="summary">Go back</button>
            <button ref="updateQuantityButton" class="btn btn-sm rounded-pill ms-2 action-button" :class="confirmButton"
              type="button" @click="confirmQuantityUpdate" :disabled="disableSubmit">Confirm changes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import Billing from "../../models/Billing";
  import { updateAdditionalProducts } from "@/services/PaymentService";
  import { createProduct } from "../../services/ProductService";
  import { uploadFile } from "../../services/FileService";
  import { Product } from "@authentic-labs/vue-component-library";
  import { useToast } from "vue-toastification";
  import { useStore } from 'vuex'
  import { computed, inject, onMounted, ref } from "vue";

  const updateQuantityButton = ref<HTMLButtonElement>()

  const props = defineProps<{
    billingDetails: Billing,
    unarchive?: boolean,
  }>();

  const $store = useStore();
  const toast = useToast();
  const emitter: any = inject("emitter");


  const products = computed(() => {
    return $store.getters.products;
  });

  const user = computed(() => {
    return $store.getters.authUser;
  })

  const freq = computed((): string => {
    if (props.billingDetails.paymentInterval === "year") {
      return "yearly";
    }
    return "monthly";
  });

  const updatedPrice = computed((): number => {
    return updatedPricing.value;
  });

  const defaultPayment = computed(() => {
    let paymentMethod: any = [];
    const found = props.billingDetails.paymentMethods!.find((element: any) => {
      element.default === true;
      paymentMethod = element;
    });
    return paymentMethod;
  });

  const placeHolderHeaderImage = computed((): string => {
    return new URL("/src/assets/images/app/placeholder16x9.png", import.meta.url).href;
  });

  const placeHolderLogoImage = computed((): string => {
    return new URL("/src/assets/images/app/placeholder1x1.png", import.meta.url).href;
  });

  const confirmedButtonHTML = `<div class="confirmed-text">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                  </svg> <span class="confirmed-text-span">Changes confirmed</span>
                </div>`;

  const showUpdatedPricing = ref(false);
  function summary() {
    emitter.emit("billing-summary");
  }

  const perProductCost = ref(1);
  const perCtaCost = ref(1);
  const perUserCost = ref(1);
  const productNumber = ref(0);
  const startingProductsNumber = ref(0);
  const startingUsersNumber = ref(0);
  const startingCtasNumber = ref(0);
  const ctaNumber = ref(0);
  const userNumber = ref(0);
  const updatedPricing = ref(0);
  const disableSubmit = ref(false);
  const confirmButton = ref("btn-primary");
  const productPriceId = ref("");
  const ctaPriceId = ref("");
  const userPriceId = ref("");
  const productQuantity = ref(0);
  const ctaQuantity = ref(0);
  const userQuantity = ref(0);
  const payLoad = ref<Partial<Product>>({});

  onMounted(() => {
    productNumber.value = props.billingDetails.additionalProducts;
    startingProductsNumber.value = props.billingDetails.additionalProducts;
    startingUsersNumber.value = props.billingDetails.additionalUsers;
    startingCtasNumber.value = props.billingDetails.additionalCtas;
    ctaNumber.value = props.billingDetails.additionalCtas;
    userNumber.value = props.billingDetails.additionalUsers;
    updatedPricing.value = props.billingDetails.planPrice;
    perProductCost.value = props.billingDetails.additionalProductPrice;
    perCtaCost.value = props.billingDetails.additionalCtaPrice;
    perUserCost.value = props.billingDetails.additionalUserPrice;
  });

  function setPriceId() {
    // props.billingDetails.paymentInterval === "year"
    //   ? (priceId.value = import.meta.env.VITE_APP_PRO_PRODUCT_YEARLY)
    //   : (priceId.value = import.meta.env.VITE_APP_ADDITIONAL_PRODUCT);
    if(props.billingDetails.subscriptionName === "Basic" && props.billingDetails.paymentInterval === "month") {
      productPriceId.value = import.meta.env.VITE_APP_BASIC_ADDITIONAL_PRODUCT;
    }
    if(props.billingDetails.subscriptionName === "Pro" && props.billingDetails.paymentInterval === "month") {
      productPriceId.value = import.meta.env.VITE_APP_PRO_ADDITIONAL_PRODUCT;
      ctaPriceId.value = import.meta.env.VITE_APP_PRO_ADDITIONAL_CTA;
    }
    if(props.billingDetails.subscriptionName === "Enterprise" && props.billingDetails.paymentInterval === "month") {
      productPriceId.value = import.meta.env.VITE_APP_ENTERPRISE_ADDITIONAL_PRODUCT;
      ctaPriceId.value = import.meta.env.VITE_APP_ENTERPRISE_ADDITIONAL_CTA;
    }
    userPriceId.value = import.meta.env.VITE_APP_ADDITIONAL_USER;
  }

  function updateProductNumbers(direction: string) {
    if(direction === "inc" && productNumber.value < 100){
      productNumber.value++;
    }
    if(direction === "dec" && productNumber.value > 0){
      productNumber.value--;
    }
    
    if (startingProductsNumber.value !== productNumber.value) {
      updatedPricing.value = updatedPricing.value +
        (productNumber.value - startingProductsNumber.value) * perProductCost.value;
      productQuantity.value = productNumber.value - props.billingDetails.additionalProducts;
      startingProductsNumber.value = productNumber.value;
      showUpdatedPricing.value = true;
    }
    if(props.billingDetails.additionalProducts === productNumber.value) {
      showUpdatedPricing.value = props.billingDetails.planPrice === updatedPrice.value ? false : true;
      startingProductsNumber.value = props.billingDetails.additionalProducts;
      return;
    } 
  }

  function updateUserNumbers(direction: string) {
    if(direction === "inc" && userNumber.value < 100){
      userNumber.value++;
    }
    if(direction === "dec" && userNumber.value > 0){
      userNumber.value--;
    }
   
    if (startingUsersNumber.value !== userNumber.value) {
      updatedPricing.value = updatedPricing.value +
        (userNumber.value - startingUsersNumber.value) * perUserCost.value;
      userQuantity.value = userNumber.value - props.billingDetails.additionalUsers;
      startingUsersNumber.value = userNumber.value;
      showUpdatedPricing.value = true;
    }
    if(props.billingDetails.additionalUsers === userNumber.value) {
      showUpdatedPricing.value = props.billingDetails.planPrice === updatedPrice.value ? false : true;
      startingUsersNumber.value = props.billingDetails.additionalUsers;
      return;
    } 
  }

  function updateCtaNumbers(direction: string) {
    if(direction === "inc" && ctaNumber.value < 100){
      ctaNumber.value++;
    }
    if(direction === "dec" && ctaNumber.value > 0){
      ctaNumber.value--;
    }
    if (startingCtasNumber.value !== ctaNumber.value) {
      updatedPricing.value = updatedPricing.value +
        (ctaNumber.value - startingCtasNumber.value) * perCtaCost.value;
      ctaQuantity.value = ctaNumber.value - props.billingDetails.additionalCtas;
      startingCtasNumber.value = ctaNumber.value;
      showUpdatedPricing.value = true;
    } 
    if(props.billingDetails.additionalCtas === ctaNumber.value) {
      showUpdatedPricing.value = props.billingDetails.planPrice === updatedPrice.value ? false : true;
      startingCtasNumber.value = props.billingDetails.additionalCtas;
      return;
    }
  }

  async function confirmQuantityUpdate() {
    setPriceId();
    let subscribeResponse = "";
    disableSubmit.value = true;

    const totalProductCompany = products.value.length;
    let addedProducts: any = [];
    let addedCtas: any = [];
    let addedUsers: any = [];
    if (productNumber.value !== props.billingDetails.additionalProducts) {
      addedProducts = [{priceId: productPriceId.value,  quantity: productQuantity.value, currentQuantity: props.billingDetails.additionalProducts}];
    }
    if (ctaNumber.value !== props.billingDetails.additionalCtas) {
      addedCtas = [{priceId: ctaPriceId.value, quantity: ctaQuantity.value, currentQuantity: props.billingDetails.additionalCtas}];
    }
    if (userQuantity.value !== props.billingDetails.additionalUsers) {
      addedUsers = [{priceId: userPriceId.value, quantity: userQuantity.value, currentQuantity: props.billingDetails.additionalUsers}];
    }
    const additionalItemsPayload = {
      additionalProducts: addedProducts.length > 0 ? addedProducts : null,
      additionalCtas: addedCtas.length > 0 ? addedCtas : null,
      additionalUsers: addedUsers.length > 0 ? addedUsers : null,
    };
    
    if (props.billingDetails.paymentMethods) {
      const paymentDetails = {
        paymentMethod: defaultPayment.value.id,
        addedItems: additionalItemsPayload,
        subscriptionType: props.billingDetails.subscriptionName,
      };
      subscribeResponse = await updateAdditionalProducts(paymentDetails);
      $store.dispatch("getSubscriptionStatus", { user: user.value });

      if (subscribeResponse === "failed") {
        paymentResponse(subscribeResponse);
        productNumber.value = props.billingDetails.additionalProducts;
        disableSubmit.value = false;
        return;
      } else {
        if (props.unarchive) {
          emitter.emit("billing-summary", "refresh");
          emitter.emit("billing-modal-close");
          emitter.emit("from-billing-unarchive");
        } else {
          if(products.value.length < props.billingDetails.productNumber + productNumber.value) {
            const remainingProducts = props.billingDetails.productNumber + productNumber.value - totalProductCompany;
            createNewProduct(remainingProducts);
          }
          updateQuantityButton.value!.innerHTML = confirmedButtonHTML;
          confirmButton.value = "btn-success";
          emitter.emit("billing-summary", "refresh");
        }
      }
    }
    paymentResponse(subscribeResponse);
  }

  async function setPlaceholderImages() {
    const headerResponse = await fetch(placeHolderHeaderImage.value);
    const logoResponse = await fetch(placeHolderLogoImage.value);
    const headerBlob = await headerResponse.blob();
    const logoBlob = await logoResponse.blob();
    const headerFile = new File([headerBlob], "placeholder16x9.png", {
      type: headerBlob.type,
    });
    const logoFile = new File([logoBlob], "placeholder1x1.png", {
      type: logoBlob.type,
    });
    const fileHeader = await uploadFile(headerFile);
    const fileLogo = await uploadFile(logoFile);
    return { fileHeaderId: fileHeader.filesId, fileLogoId: fileLogo.filesId };
  }

  async function createNewProduct(quantity: number) {
    payLoad.value = {
      model: "",
      name: "Product name",
      status: "active",
    };
    const placeholderResponse = await setPlaceholderImages();
    payLoad.value.imageFile = { filesId: placeholderResponse.fileHeaderId };
    payLoad.value.appLogoFile = { filesId: placeholderResponse.fileLogoId };
    try {
      for (let i = 0; i < quantity; i++) {
        const products = await createProduct(user.value.companyId, payLoad.value);
        toast.success("New Product was successfully added.");
        emitter.emit("newProductAdded", products);
      }
    } catch (error) {
      toast.error("Something went wrong. Product was not added.");
      console.error("Error adding new product: " + error);
    }
  }

  function paymentResponse(status: string) {
    if (status === "success") {
      toast.success("Your subscription has been successfully updated!");
      //emitter.emit("subscription-modal");
    } else {
      toast.error("There was an issue with subscripton update. Please try again.");
    }
  }
</script>

<style scoped>
.go-summary {
  text-decoration: none;
  outline: 0 !important;
  box-shadow: none;
  padding: 0;
  /* padding-left: 2rem; */
}

.spinner {
  padding: 0;
  width: 7rem;
  text-align: center;
  overflow: hidden;
  background-image: none;
}

.spinner-button,
.spinner-button:active .spinner-button:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.current {
  border-bottom: 1px solid lightgray;
  padding-bottom: .5rem;
}

.subscription-plan {
  font-family: "proximanova-bold", sans-serif;
  font-size: 20px;
}

.subscription-price {
  font-family: "proximanova-regular", sans-serif;
  font-size: 20px;
}

.current-payment {
  font-size: 18px;
}

.default-payment {
  font-size: 12px;
}

.btn.btn-success {
  transition-duration: 0.75s;
  animation-name: colorTransition;
}

@keyframes colorTransition {
  from {
    background-color: #3679e8 !important;
  }

  to {
    background-color: #38c172 !important;
  }
}
</style>
