/**
 * Vuex store for company
 */
import { Company, Content } from "@authentic-labs/vue-component-library";
import { initial, min } from "lodash";
import ContentSection from "../../models/ContentSection";
import { fetchCompany, updateCompany } from "../../services/CompanyService";
import { getPlans } from "../../services/PaymentService";
import User from "../../models/User";
import {
  updateCompanyAdmin,
  fetchCompanyAdmin,
} from "../../services/AdminService";
import { Commit } from "vuex";
import { getCustomerStatus } from "@/services/PaymentService";

export type CompanyState = {
  _company: Company;
  contentSections: ContentSection;
  customSections: ContentSection;
  paidSections: ContentSection;
  logoFile: File;
  stats: { userCount: number; productCount: number };
  status: { active: number; inactive: number };
  subscription: {
    isActive: boolean;
    isPro: boolean;
    hasAddons: boolean;
    // numberAddons: number;
    frequency: string;
    planType: string;
    productSections: number;
    additionalProducts: number;
    additionalCtas: number;
    additionalUsers: number;
  };
};

const initialState = {
  _company: {},
  contentSections: [],
  customSections: [],
  paidSections: [],
  logoFile: {},
  stats: [],
  status: { active: 0, inactive: 0 },
  subscription: {},
};

export default {
  state: initialState,

  getters: {
    company(state: CompanyState) {
      return state._company;
    },
    companyLogoFile(state: CompanyState) {
      return state.logoFile;
    },
    contentSection(state: CompanyState) {
      return state.contentSections;
    },
    customSection(state: CompanyState) {
      return state.customSections;
    },
    paidSection(state: CompanyState) {
      return state.paidSections;
    },
    stats(state: CompanyState) {
      return state.stats;
    },
    //TODO quick drop to get admin sidebar going revisit
    companies(state: CompanyState) {
      return state.status;
    },
    subscription(state: CompanyState) {
      return state.subscription;
    },
  },

  mutations: {
    setCompany(state: CompanyState, company: Company) {
      state._company = company;
    },
    setContentSection(state: CompanyState, contentSections: ContentSection) {
      state.contentSections = contentSections;
    },
    setCustomSection(state: CompanyState, customSections: ContentSection) {
      state.customSections = customSections;
    },
    setPaidSection(state: CompanyState, paidSections: ContentSection) {
      state.paidSections = paidSections;
    },
    setCompanies(
      state: CompanyState,
      status: { active: number; inactive: number }
    ) {
      state.status = status;
    },
    setStats(
      state: CompanyState,
      stats: { userCount: number; productCount: number }
    ) {
      state.stats = stats;
    },
    setCompanyLogoFile(state: CompanyState, logoFile: File) {
      state.logoFile = logoFile;
    },
    setUnlimitedProducts(state: CompanyState, unlimitedProducts: boolean) {
      state._company.unlimitedProducts = unlimitedProducts;
    },
    setUnlimitedSections(state: CompanyState, unlimitedSections: boolean) {
      state._company.unlimitedSections = unlimitedSections;
    },
    setUnlimitedCTAs(state: CompanyState, unlimitedCTAs: boolean) {
      state._company.unlimitedCTAs = unlimitedCTAs;
    },
    setDemo(state: CompanyState, demo: boolean) {
      state._company.demo = demo;
    },
    setActive(state: CompanyState, active: string) {
      state._company.status = active;
    },
    resetCompany(state: CompanyState) {
      Object.assign(state, initialState);
    },
    setSubscription(
      state: CompanyState,
      subscription: {
        isActive: boolean;
        isPro: boolean;
        hasAddons: boolean;
        // numberAddons: number;
        frequency: string;
        planType: string;
        productSections: number;
        additionalProducts: number;
        additionalCtas: number;
        additionalUsers: number;
      }
    ) {
      state.subscription = subscription;
    },
  },

  actions: {
    async getCompany({ commit }: { commit: Commit }, companyId: number) {
      try {
        const {
          company,
          customSections,
          contentSections,
          paidSections,
          stats,
        } = await fetchCompany(companyId);
        commit("setCompany", company);
        commit("setCustomSection", customSections);
        commit("setContentSection", contentSections);
        commit("setPaidSection", paidSections);
        commit("setStats", stats);
      } catch (error) {
        console.error("Error retrieving company: ", error);
      }
    },
    async updateCompany(
      { commit }: { commit: Commit },
      payLoad: { companyId: number; company: Company }
    ) {
      try {
        const { response } = await updateCompany(
          payLoad.companyId,
          payLoad.company
        );
        //}
        commit("setCompany", payLoad.company);
      } catch (error) {
        console.error("Error retrieving company: ", error);
      }
    },
    async setCompanyStore(
      { commit }: { commit: Commit },
      companyRepsonse: {
        company: Company;
        customSections: ContentSection;
        contentSections: ContentSection;
        paidSections: ContentSection;
        stats: { userCount: number; productCount: number };
      }
    ) {
      commit("setCompany", companyRepsonse.company);
      commit("setCustomSection", companyRepsonse.customSections);
      commit("setContentSection", companyRepsonse.contentSections);
      commit("setPaidSection", companyRepsonse.paidSections);
      commit("setStats", companyRepsonse.stats);
    },
    async getSubscriptionStatus(
      { commit }: { commit: Commit },
      payLoad: { user: User; companyId: number }
    ) {
      if (payLoad.user.role === "Superadmin") {
        const id = payLoad.companyId ?? 1;
        const response = await fetchCompany(id);
        const planResponse = await getPlans(); 
        const plan = planResponse.filter( 
          (x) => x.name == response.company.type
        )[0];
        // const plan = null
        if (!plan) {
          commit("setSubscription", {
            isActive: true,
            isPremium: true,
            additionalProducts: 1000,
            additionalCtas: 50,
            additionalUsers: 10,
            hasAddons: true,
            frequency: "year",
          });
        } else {
          commit("setSubscription", {
            isActive: true,
            isPremium: plan.premium,
            additionalProducts: 50,
            additionalCtas: 50,
            additionalUsers: 10,
            hasAddons: false,
            frequency: plan.interval,
            planType: response.company.type,
            productSections: response.company.unlimitedSections
              ? 1000
              : plan.productSections,
            productNumber: response.company.unlimitedProducts
              ? 1000
              : plan.productNumber,
            // productSections: plan.productSections,
            // productNumber: plan.productNumber,
          });
        }
        return;
      }
      try {
        const response = await getCustomerStatus(payLoad.user);
        if (response?.role === "companySubscribedUser") {
          let isPremium = false;
          response.subscriptionName.toLowerCase() === "basic" ||
          response.subscriptionName.toLowerCase() === "pro"
            ? (isPremium = false)
            : (isPremium = true);
          commit("setSubscription", {
            isActive: true,
            isPremium: isPremium,
          });
        } else if (response?.subscriptionName) {
          let isPremium = false;
          let hasAddons = false;
          response.subscriptionName.toLowerCase() === "basic" ||
          response.subscriptionName.toLowerCase() === "pro"
            ? (isPremium = false)
            : (isPremium = true);
          response.additionalProducts > 0
            ? (hasAddons = true)
            : (hasAddons = false);
          commit("setSubscription", {
            isActive: true,
            isPremium: isPremium,
            frequency: response?.paymentInterval,
            // numberAddons: response?.additionalProducts,
            additionalProducts: response?.additionalProducts,
            additionalCtas: response?.additionalCtas,
            additionalUsers: response?.additionalUsers,
            hasAddons: hasAddons,
            planType: response.subscriptionName,
            productSections: response.productSections,
            productNumber: response.productNumber,
            ctaNumber: response.ctaNumber,
            userNumber: response.userNumber
          });
        } else {
          const companyResponse = await fetchCompany(payLoad.companyId);
          if (companyResponse.company.demo) {
            commit("setSubscription", {
              isActive: true,
              isPremium: false,
              frequency: "year",
              additionalProducts: 0,
              hasAddons: false,
              planType: "basic",
              productSections: companyResponse.company.unlimitedSections
                ? 1000
                : 3,
              productNumber: companyResponse.company.unlimitedProducts
                ? 1000
                : 1,
              ctaNumber: companyResponse.company.unlimitedCTAs
                ? 1000
                : 1,
            });
          } else {
            commit("setSubscription", {
              isActive: false,
            });
          }
        }
      } catch (error) {
        console.error("Error retrieving subscription status: ", error);
      }
    },
  },
};
